import { Box } from '@material-ui/core';
import React from 'react';

import { renderTimeSlot } from '../../calendar/calendar-form-new/appointment-summary';
import { AppointmentData } from '../hooks/usePersistedState';
import { getAddress } from './AppointmentPreview';

interface AppointmentDetailsProps {
  appointmentData: AppointmentData;
}

export const AppointmentDetails = ({
  appointmentData,
}: AppointmentDetailsProps) => {
  const lines: string[] = [getAddress(appointmentData)];

  if (appointmentData.timeSlot) {
    lines.push(renderTimeSlot(appointmentData.timeSlot));
  }

  if (
    appointmentData.fullName &&
    appointmentData.email &&
    appointmentData.phone
  ) {
    lines.push(
      `${appointmentData.fullName}, ${appointmentData.email}, ${appointmentData.phone}`,
    );
  }

  return (
    <Box className="marketplace-appointment-summary" mt="20px">
      <div className="marketplace-heading">
        <span className="marketplace-heading__accent">Your appointment</span>{' '}
        details
      </div>

      {lines.map((line, i) => (
        <Box key={i} className="marketplace-appointment-summary__line" mt="1px">
          <div className="marketplace-appointment-summary__value">{line}</div>
        </Box>
      ))}
    </Box>
  );
};
