import clsx from 'clsx';
import { addDays, format, getMonth } from 'date-fns';
import * as React from 'react';

const getDateRangeLabel = (date1: Date, date2: Date) => {
  if (getMonth(date1) === getMonth(date2)) {
    return (
      <>
        {format(date1, 'd')}
        {'-'}
        <span className="no-break">{format(date2, 'd MMMM')}</span>
      </>
    );
  }
  return (
    <>
      {format(date1, 'd MMM')}
      {' - '}
      <span className="no-break">{format(date2, 'd MMM')}</span>
    </>
  );
};

type CalendarWeekProps = {
  weekStart: Date;
  title: string | null;
};

export const CalendarWeek: React.FC<CalendarWeekProps> = ({
  children,
  weekStart,
  title,
}) => {
  const weekEnd = addDays(weekStart, 6);
  const dateRange = getDateRangeLabel(weekStart, weekEnd);

  return (
    <div>
      <div className="marketplace-calendar-week">
        {title && <h2 className="marketplace-calendar-week__title">{title}</h2>}
        <h3
          className={clsx(
            'marketplace-calendar-week__date-range',
            !title && 'marketplace-calendar-week__date-range--no-title',
          )}
        >
          {dateRange}
        </h3>
      </div>

      <div className="marketplace-calendar-week-table">{children}</div>
    </div>
  );
};
