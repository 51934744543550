import { TextField } from '@material-ui/core';
import React from 'react';

import { Button } from '../../button';
import { AppointmentData } from '../hooks/usePersistedState';
import { AddressData, PostcodeLookup } from './PostcodeLookup';

interface AddressInputGroupContentProps {
  state: AppointmentData;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAddressSelect: (address: AddressData) => void;
  shouldAutoFocusPostcodeLookup?: boolean;
}

export const AddressInputGroupContent = ({
  state,
  onChange,
  onAddressSelect,
  shouldAutoFocusPostcodeLookup,
}: AddressInputGroupContentProps) => {
  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: 'rgb(245,245,245)',
          border: '1px solid rgb(200,200,200)',
        }}
      >
        <legend style={{ fontWeight: 600 }}>Start typing your address</legend>
        <PostcodeLookup
          addressSelectedCallback={onAddressSelect}
          shouldAutoFocus={shouldAutoFocusPostcodeLookup}
        />
      </div>
      <TextField
        required
        fullWidth
        label="Address line 1"
        margin="normal"
        name="line1"
        variant="outlined"
        value={state.line1}
        onChange={onChange}
      />
      <TextField
        fullWidth
        label="Address line 2"
        margin="normal"
        name="line2"
        variant="outlined"
        value={state.line2}
        onChange={onChange}
      />
      <TextField
        required
        fullWidth
        label="Town"
        margin="normal"
        name="town"
        value={state.town}
        variant="outlined"
        onChange={onChange}
      />
      <TextField
        required
        fullWidth
        label="Postcode"
        margin="normal"
        name="postcode"
        value={state.postcode}
        variant="outlined"
        onChange={onChange}
      />
      <div style={{ paddingTop: 15 }}>
        <Button type="submit" variant="primary">
          Continue
        </Button>
      </div>
    </div>
  );
};
