import '../../../styles/components/calendar.scss';
import '../../../styles/slider.scss';

import { isSameWeek, isWeekend, startOfWeek } from 'date-fns';
import React from 'react';
import Slider, { Settings } from 'react-slick';
import ReactTooltip from 'react-tooltip';

import { ArrowLeftIconV2 } from '../../icons/ArrowLeftIconV2';
import { ArrowRightIconV2 } from '../../icons/ArrowRightIconV2';
import { BookableSlot } from '../use-bookable-slots';
import {
  groupByDay,
  groupByWeek,
  splitTimeSlotValue,
  toStartAndEndDateTime,
  toTimeSlot,
  toTimeSlotValue,
} from '../utils';
import { CalendarDay } from './calendar-day';
import {
  CalendarSlotInput,
  CalendarSlotInputProps,
} from './calendar-slot-input';
import { CalendarTimes, CalendarTimesProps } from './calendar-times';
import { CalendarWeek } from './calendar-week';

export const extractDaySlots = (day: BookableSlot[]) => {
  const date = day[0].startDateTime;
  const slots = day.map((slot) => {
    const timeSlot = toTimeSlot(slot.startDateTime, slot.endDateTime);
    return {
      time: timeSlot.slot,
      available: slot.isAvailable,
      isEcoSlot: slot.isRatingGood,
      isBestSlot: slot.isBestSlot,
    };
  });
  return { date, slots };
};

const weekContainsDate = (week: BookableSlot[][], date: Date): boolean => {
  if (week.length === 0 || week[0].length === 0) {
    return false;
  }
  return isSameWeek(week[0][0].startDateTime, date, { weekStartsOn: 1 });
};

type SlickButtonFixProps = {
  className?: string;
  currentSlide?: number;
  slideCount?: number;
  name?: string;
};

// Workaround: https://github.com/akiran/react-slick/issues/1195#issuecomment-390383615
const SlickButtonFix: React.FC<SlickButtonFixProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  currentSlide,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  slideCount,
  children,
  ...props
}) => (
  <button
    {...props}
    className={`${props.className} marketplace-calendar-week__button`}
    type="button"
  >
    {children}
  </button>
);

export type CalendarInputProps = {
  name: string;
  bookableSlots: BookableSlot[];
  slotLabels: CalendarTimesProps['timeLabels'];
  inputRef?: CalendarSlotInputProps['inputRef'];
  initialValue?: string | undefined;
  value?: string;
};

export const CalendarInput: React.FC<CalendarInputProps> = ({
  name,
  inputRef,
  bookableSlots,
  slotLabels,
  initialValue,
  value: selectedValue,
}) => {
  const weeks = groupByWeek(groupByDay(bookableSlots));
  const firstAvailableSlot = bookableSlots.find((slot) => slot.isAvailable);

  const initialTimeSlot = initialValue
    ? splitTimeSlotValue(initialValue)
    : undefined;

  const firstSlotShown =
    initialTimeSlot !== undefined
      ? toStartAndEndDateTime(initialTimeSlot[0], initialTimeSlot[1])[0]
      : firstAvailableSlot?.startDateTime;

  const initialWeekIndex = firstSlotShown
    ? weeks.findIndex((week) => weekContainsDate(week, firstSlotShown))
    : undefined;

  const sliderSettings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    initialSlide: initialWeekIndex ?? 0,
    nextArrow: (
      <SlickButtonFix aria-label="Next Week">
        <ArrowRightIconV2 />
      </SlickButtonFix>
    ),
    prevArrow: (
      <SlickButtonFix aria-label="Previous Week">
        <ArrowLeftIconV2 />
      </SlickButtonFix>
    ),
  };

  return (
    <>
      <Slider {...sliderSettings}>
        {weeks.map((week, weekIndex) => {
          const weekStart = startOfWeek(week[0][0].startDateTime, {
            weekStartsOn: 1,
          });

          const title = ['This Week', 'Next Week'][weekIndex] || null;

          const weekHasSlots = week.some((day) =>
            day.some((slot) => slot.isAvailable),
          );

          return (
            <div
              className="marketplace-calendar"
              key={weekIndex}
              data-date={weekStart.toISOString()}
            >
              <CalendarWeek weekStart={weekStart} title={title}>
                {weekHasSlots && (
                  <>
                    <CalendarTimes timeLabels={slotLabels} />
                    {week.map((day) => {
                      const { date, slots } = extractDaySlots(day);

                      return (
                        <CalendarDay key={date.getTime()} date={date}>
                          {slots.map((slot) => {
                            const {
                              time,
                              available,
                              isEcoSlot,
                              isBestSlot,
                            } = slot;
                            const value = toTimeSlotValue({ date, slot: time });
                            return (
                              <CalendarSlotInput
                                key={time}
                                title={time}
                                group={name}
                                value={value}
                                checked={value === selectedValue}
                                disabled={!available}
                                inputRef={inputRef}
                                isEcoSlot={isEcoSlot ?? false}
                                isBestSlot={isBestSlot ?? false}
                              />
                            );
                          })}
                        </CalendarDay>
                      );
                    })}
                  </>
                )}

                {!weekHasSlots && (
                  <div className="marketplace-calendar-week__empty-content">
                    <p>No slots available for this week</p>
                  </div>
                )}
              </CalendarWeek>
            </div>
          );
        })}

        <div>
          <div className="marketplace-calendar-week">
            <h2 className="marketplace-calendar-week__date-range marketplace-calendar-week__date-range--no-title heading2">
              More Availability
            </h2>
          </div>
          <div className="marketplace-calendar-week__empty-content">
            <p>
              New availability is released each week, please come back on Monday
              to see more choices
            </p>
          </div>
        </div>
      </Slider>

      <ReactTooltip
        event="click"
        afterShow={() => setTimeout(ReactTooltip.hide, 3000)}
        backgroundColor="black"
        offset={{ top: 0 }}
      />
    </>
  );
};
