import { format } from 'date-fns';
import * as React from 'react';

type CalendarDayProps = {
  date: Date;
};

export const CalendarDay: React.FC<CalendarDayProps> = ({ date, children }) => {
  const weekday = format(date, 'EEEEE');
  const displayDate = format(date, 'd');
  const tooltipText = 'Select from the available times below';

  return (
    <div className="marketplace-calendar-day marketplace-calendar-week-table__day">
      <div
        data-tip={tooltipText}
        className="marketplace-calendar__slot marketplace-calendar-day__slot"
      >
        <p className="marketplace-calendar-day__weekday">{weekday}</p>
        <p className="marketplace-calendar-day__date">{displayDate}</p>
      </div>

      {children}
    </div>
  );
};
