import clsx from 'clsx';
import React, { useMemo } from 'react';

import { Button } from '../../button';
import { JobType } from '../utils/jobTypes';

export interface JobTypeSelectorButtonProps {
  options: JobType[];
  value?: string;
  onChange?: (value: string) => void;
}

export const JobTypeSelectorButtons = ({
  options,
  value,
  onChange,
}: JobTypeSelectorButtonProps) => {
  const jobTypes = useJobTypesWithPreselectedValueFirst(value, options);

  return (
    <div className="marketplace-job-types">
      <div className="marketplace-job-types__buttons">
        {jobTypes.map((jobType) => (
          <Button
            key={jobType.value}
            className={clsx(
              'marketplace-job-types__button',
              jobType.value === value &&
                'marketplace-job-types__button--selected',
            )}
            onClick={() => onChange?.(jobType.value)}
          >
            {jobType.label}
          </Button>
        ))}
      </div>
    </div>
  );
};

function useJobTypesWithPreselectedValueFirst(
  value: string | undefined,
  options: JobType[],
) {
  const jobTypes = useMemo(() => {
    if (!value) return options;
    const selectedJobType = options.find((t) => t.value === value);
    if (!selectedJobType) return options;
    return [selectedJobType, ...options.filter((t) => t.value !== value)];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return jobTypes;
}
