import { get } from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form/dist/index.ie11';
import { FieldError } from 'react-hook-form/dist/types/form';

import { isNotNullOrUndefined } from '../../../utils/useNotNullOrUndefined';
import { isNullOrUndefined } from '../../../utils/useNullOrUndefined';
import { getFieldValidators } from '../utils/fieldValidation';
import { TextInput } from './TextInput';

interface TextFieldProps {
  name: string;
  label: string;
  type?: string;
  autoComplete?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TextField = ({
  name,
  label,
  required,
  onChange,
  type,
  autoComplete,
}: TextFieldProps) => {
  const form = useFormContext();
  const error = get(form.errors, name) as FieldError | undefined;

  function validateField(value: unknown) {
    const validators = getFieldValidators({ required, type });

    for (const validate of validators) {
      const error = validate(value);

      if (error) {
        return error;
      }
    }
  }

  return (
    <Controller
      name={name}
      control={form.control}
      rules={{ validate: validateField }}
      render={(inputProps) => (
        <TextInput
          name={name}
          {...inputProps}
          label={label}
          required={required}
          type={type}
          autoComplete={autoComplete}
          helperText={error?.message}
          invalid={isNotNullOrUndefined(error)}
          valid={isNullOrUndefined(validateField(inputProps.value))}
          onChange={(e) => {
            onChange?.(e);
            inputProps.onChange(e);
          }}
        />
      )}
    />
  );
};
