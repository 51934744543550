import { Grid, LinearProgress, makeStyles } from '@material-ui/core';
import { useLocation } from '@reach/router';
import clsx from 'clsx';
import React from 'react';

export const HeaderNew = () => {
  const route = useLocation();

  const steps = [
    {
      name: 'Enter address',
      isActive: '/booking/address' === route.pathname,
    },
    {
      name: 'Select time',
      isActive: '/booking/appointment' === route.pathname,
    },
    {
      name: 'Submit',
      isActive: '/booking/resident' === route.pathname,
    },
    {
      name: 'Confirmation',
      isActive: route.pathname.startsWith('/booking/confirmation'),
      hidden: true,
    },
  ];

  const privateBookingSteps = [
    {
      name: 'Enter address',
      isActive: '/private-booking/address' === route.pathname,
    },
    {
      name: 'Select time',
      isActive: '/private-booking/appointment' === route.pathname,
    },
    {
      name: 'Submit',
      isActive: '/private-booking/resident' === route.pathname,
    },
    {
      name: 'Confirmation',
      isActive: route.pathname.startsWith('/private-booking/confirmation'),
      hidden: true,
    },
  ];

  const currentSteps = route.pathname.startsWith('/private-booking')
    ? privateBookingSteps
    : steps;

  const currentStepIndex = currentSteps.findIndex((step) => step.isActive);

  return (
    <div className="marketplace-header">
      <div>
        <Grid container spacing={3}>
          {currentSteps
            .filter((step) => !step.hidden)
            .map((step, i) => (
              <Step
                index={i}
                key={step.name}
                name={step.name}
                isActive={i === currentStepIndex}
                isCompleted={i < currentStepIndex}
              />
            ))}
        </Grid>
      </div>
    </div>
  );
};

const useStepStyles = makeStyles(() => ({
  root: {
    marginTop: '5px',
  },
  text: {
    color: '#292B2E',
    letterSpacing: '-0.04em',
  },
  step: {
    fontSize: '12px',
    lineHeight: '24px',
  },
  name: {
    marginTop: '-7px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  progressRoot: {
    marginTop: '1px',
    height: 3,
    borderRadius: 1.5,
  },
  progressColorPrimary: {
    backgroundColor: '#C3C9D7',
  },
  progressBar: {
    borderRadius: 1.5,
    backgroundColor: '#0B4ED5',
  },
  stateActive: {
    '& $text': {
      color: '#0B4ED5',
    },
  },
  stateCompleted: {
    '& $text': {
      color: '#C3C9D7',
    },
    '& $progressBar': {
      backgroundColor: '#0DBB7C',
    },
  },
}));

interface StepProps {
  index: number;
  name: string;
  isActive?: boolean;
  isCompleted?: boolean;
}

const Step = ({ index, name, isActive, isCompleted }: StepProps) => {
  const classes = useStepStyles();
  const state = isCompleted ? 'Completed' : isActive ? 'Active' : undefined;

  // TODO: update typescript to remove the cast and infer the type automatically
  const stateClass = `state${state}` as 'stateActive' | 'stateCompleted';

  return (
    <Grid className={clsx(classes.root, state && classes[stateClass])} item xs>
      <h3 className={classes.text}>
        <div className={classes.step}>Step {index + 1}</div>
        <div className={classes.name}>{name}</div>
      </h3>

      <LinearProgress
        variant="determinate"
        value={isActive || isCompleted ? 100 : 0}
        classes={{
          root: classes.progressRoot,
          bar: classes.progressBar,
          colorPrimary: classes.progressColorPrimary,
        }}
      />
    </Grid>
  );
};
