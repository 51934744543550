import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { Subject } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';

export type SearchAddresses = (query: string) => void;
export type AddCategory = (
  addressCategory: AddressCategory | undefined,
) => void;

export interface AddressCategory {
  id: string;
  name: string;
}

export interface AddressSuggestion {
  id: string;
  type: string;
  summaryline: string;
  locationsummary: string;
  count: number;
}

const POSTCODER_URL = 'https://ws.postcoder.com/pcw/autocomplete/find';
const COUNTRY = 'UK';
const searchSubject$ = new Subject<string>();

const handleAddressSearch = async (
  query: string,
  userId: string,
  pathfilter?: string,
) => {
  const encodePathfilter = pathfilter ? encodeURIComponent(pathfilter) : '';
  const url = `${POSTCODER_URL}?identifier=${userId}&query=${query}&pathfilter=${encodePathfilter}&country=${COUNTRY}&apikey=${process.env.POSTCODER_API_KEY}`;
  const queryResult = await fetch(url, {
    method: 'GET',
  });

  if (queryResult.status >= 200 && queryResult.status <= 299) {
    return (await queryResult.json()) as AddressSuggestion[];
  }
  return [];
};

export const useSearchAddress = (): [
  string,
  AddressSuggestion[],
  SearchAddresses,
  AddCategory,
  AddressCategory | undefined,
  boolean,
] => {
  const [suggestedAddresses, setSuggestedAddresses] = useState<
    AddressSuggestion[]
  >([]);

  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [category, setCategory] = useState<AddressCategory>();

  const searchAddressesCallback = useCallback(
    async (query: string, newCategory?: AddressCategory) => {
      setLoading(true);
      const suggestedAddresses = await handleAddressSearch(
        query,
        'marketplace',
        newCategory?.id,
      );
      setSuggestedAddresses(suggestedAddresses);
      setLoading(false);
    },
    [],
  );

  const triggerSearchRequest = async (query: string) => {
    setQuery(query);
    searchSubject$.next(query);
  };

  const searchCategory = (addressCategory: AddressCategory | undefined) => {
    setCategory(addressCategory);
    searchAddressesCallback(query, addressCategory);
  };

  useEffect(() => {
    const subscription = searchSubject$
      .pipe(
        debounceTime(200), // Only call the query every 200ms of no user input
        map((query) => query.trim()), // Trim whitespace
        filter((query) => query.length > 2), // Postcoder requires a minimum of 3 char for search
      )
      .subscribe({
        next: (query: string) => searchAddressesCallback(query, category),
      });

    return () => subscription.unsubscribe();
  }, [searchAddressesCallback, category]);

  return [
    query,
    suggestedAddresses,
    triggerSearchRequest,
    searchCategory,
    category,
    loading,
  ];
};
