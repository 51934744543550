import '../../../styles/components/booking.scss';
import '../../../styles/components/confirmation.scss';

import React from 'react';
import { useForm } from 'react-hook-form/dist/index.ie11';

import { AsyncComponent } from '../../async-component';
import { Button } from '../../button';
import { AppointmentData } from '../../marketplace/hooks/usePersistedState';
import { SpinnerSection } from '../../spinner-section';
import { CalendarKey } from '../calendar-key';
import { SlotAvailability } from '../use-bookable-slots';
import { sortByStart } from '../utils';
import { AppointmentSummary } from './appointment-summary';
import { CalendarInput, CalendarInputProps } from './calendar-input';

export interface AppointmentValues {
  timeSlot: string | undefined;
}

export type CalendarFormProps = {
  isSlotsLoading: boolean;
  slotLabels: CalendarInputProps['slotLabels'];
  slotAvailability: SlotAvailability | undefined;
  slotError: Error | undefined;
  defaultValues?: Partial<AppointmentValues>;
  submitBooking: (values: AppointmentValues) => Promise<void>;
  showKey?: boolean;
  appointmentData: AppointmentData;
  isPrivateBooking?: boolean;
};

export const CalendarForm: React.FC<CalendarFormProps> = ({
  isSlotsLoading,
  slotLabels,
  slotAvailability,
  slotError,
  submitBooking,
  defaultValues,
  appointmentData,
  isPrivateBooking,
}) => {
  const { register, handleSubmit, formState, watch } = useForm<
    AppointmentValues
  >({
    defaultValues,
  });

  const sortedSlots = sortByStart(slotAvailability?.bookableSlots ?? []);

  const timeSlot = watch('timeSlot');

  React.useEffect(() => {
    const eventListener = (event: BeforeUnloadEvent) => {
      if (timeSlot && defaultValues?.timeSlot !== timeSlot) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', eventListener);

    return () => {
      window.removeEventListener('beforeunload', eventListener);
    };
  });

  return (
    <form
      onSubmit={handleSubmit(submitBooking)}
      className="marketplace-calendar-v2"
    >
      <div className="marketplace-heading">
        <span className="marketplace-heading__accent">
          Choose date and time
        </span>{' '}
        you prefer
      </div>

      <AsyncComponent
        asyncState={{
          error: slotError,
          isLoading: isSlotsLoading,
          hasResult: Boolean(slotAvailability),
        }}
        renderLoading={<SpinnerSection />}
        renderError={() => (
          <section className="section">
            <div className="container--small center">
              <h2 className="heading1">Something went wrong</h2>
              <p className="faded-text">
                We couldn&apos;t retrieve the slots available for booking.
                <br />
                Please try again later.
              </p>
            </div>
          </section>
        )}
      >
        <>
          <CalendarInput
            name="timeSlot"
            slotLabels={slotLabels}
            bookableSlots={sortedSlots}
            inputRef={register({ required: true })}
            initialValue={defaultValues?.timeSlot}
            value={timeSlot}
          />
          <div className="marketplace-calendar__timezone-message">
            All times are UK Time.
          </div>
        </>
      </AsyncComponent>

      <AppointmentSummary
        appointmentData={{
          ...appointmentData,
          timeSlot: timeSlot ?? appointmentData?.timeSlot,
        }}
      />

      {timeSlot && (
        <div className="marketplace-submit">
          <Button
            className="marketplace-submit__button"
            variant="primary"
            type="submit"
            disabled={formState.isSubmitting}
            busy={formState.isSubmitting}
          >
            Confirm time &amp; go to step 3
          </Button>
        </div>
      )}
      {isPrivateBooking && <CalendarKey isCheckbox={isPrivateBooking} />}
    </form>
  );
};
