import { makeStyles, TextField as MuiTextField } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 4,
    marginBottom: 4,
  },
  inputLabel: {
    fontSize: '12px',
    lineHeight: '24px',
    color: '#A1A6B3 !important',
  },
  inputLabelShrink: {
    transform: 'translate(12px, 2px) !important',
  },
  asterisk: {
    color: '#DD2417',
  },
  inputRoot: {
    border: '1px solid #fff',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.02em',
    color: '#292b2e',

    '& input': {
      padding: '22px 12px 8px',
    },

    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      borderColor: '#0B4ED5',
    },
  },
  helperText: {
    marginBottom: '-4px',
    fontSize: '12px',
    lineHeight: '18px',
  },
  focused: {},
  valid: {
    '& $inputRoot': {
      borderColor: '#0DBB7C',
    },
  },
  invalid: {
    '& $inputRoot': {
      borderColor: '#DD2417',
      color: '#DD2417',
    },
  },
}));

interface TextInputProps {
  label: string;
  value?: unknown;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  helperText?: string;
  required?: boolean;
  valid?: boolean;
  invalid?: boolean;
  type?: string;
  autoComplete?: string;
}

export const TextInput = ({
  label,
  value,
  name,
  onChange,
  onBlur,
  required,
  valid,
  invalid,
  helperText,
  type,
  autoComplete,
}: TextInputProps) => {
  const classes = useStyles();

  return (
    <MuiTextField
      label={label}
      value={value}
      name={name}
      required={required}
      fullWidth
      variant="filled"
      className={clsx(
        classes.root,
        valid && classes.valid,
        invalid && classes.invalid,
      )}
      InputLabelProps={{
        classes: {
          root: classes.inputLabel,
          asterisk: classes.asterisk,
          shrink: classes.inputLabelShrink,
        },
        shrink: true,
      }}
      FormHelperTextProps={{
        error: invalid,
        classes: { root: classes.helperText },
      }}
      InputProps={{
        classes: { root: classes.inputRoot, focused: classes.focused },
        disableUnderline: true,
        required: false,
      }}
      helperText={helperText}
      error={invalid}
      type={type}
      autoComplete={autoComplete}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
