import React from 'react';

import { CheckboxIcon } from '../../icons/CheckboxIcon';
import { CheckboxSelectedIcon } from '../../icons/CheckboxSelectedIcon';

const selectColor = ({
  isEcoSlot,
  isBestSlot,
}: {
  isEcoSlot: boolean;
  isBestSlot: boolean;
}) => {
  return isBestSlot ? '#5028e2' : isEcoSlot ? 'green' : undefined;
};

export type CalendarSlotInputProps = {
  title: string;
  group: string;
  value: string;
  disabled: boolean;
  checked: boolean;
  isEcoSlot: boolean;
  isBestSlot: boolean;
  inputRef?: (instance: HTMLInputElement | null) => void;
};

export const CalendarSlotInput: React.FC<CalendarSlotInputProps> = ({
  title,
  group,
  value,
  disabled,
  inputRef,
  checked,
  isEcoSlot,
  isBestSlot,
}) => {
  const toolTipText = 'Not available';

  return (
    <div className="marketplace-calendar__slot marketplace-calendar-slot">
      {disabled && (
        <div
          data-tip={toolTipText}
          className="calendar__time-slot--empty-slot"
        />
      )}
      {!disabled && (
        <>
          <div className="marketplace-calendar-slot__container">
            <input
              type="radio"
              className="marketplace-calendar-slot__input"
              ref={inputRef}
              id={value}
              name={group}
              value={value}
              disabled={disabled}
            />

            {checked ? (
              <CheckboxSelectedIcon
                color={selectColor({ isBestSlot, isEcoSlot })}
              />
            ) : (
              <CheckboxIcon color={selectColor({ isBestSlot, isEcoSlot })} />
            )}
            {isBestSlot ? (
              <div className="marketplace-calendar-slot--rated-best" />
            ) : isEcoSlot ? (
              <div className="marketplace-calendar-slot--rated-good" />
            ) : null}
          </div>

          <label className="marketplace-calendar-slot__label" htmlFor={value}>
            <span className="screen-reader">{title}</span>
          </label>
        </>
      )}
    </div>
  );
};
