import React from 'react';

import { isNewAddressSectionEnabled } from '../../../utils/feature-flags';
import { AppointmentData } from '../hooks/usePersistedState';
import { AddressInputGroupContent } from './AddressInputGroupContent';
import { AddressInputGroupContentNew } from './AddressInputGroupContentNew';
import { AddressData } from './PostcodeLookup';

interface AddressInputGroupProps {
  state: AppointmentData;
  setState: (nextState: AppointmentData) => void;
  resetForm: (nextState: AppointmentData) => void;
  shouldAutoFocusPostcodeLookup?: boolean;
}

export const AddressInputGroup = ({
  state,
  setState,
  resetForm,
  shouldAutoFocusPostcodeLookup,
}: AddressInputGroupProps) => {
  const addAddressToState = (address: AddressData) => {
    const nextState = { ...state, ...address };
    setState(nextState);
    resetForm(nextState);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 1) {
      window.dataLayer?.push({ event: `start-${event.target.name}` });
    }
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  if (isNewAddressSectionEnabled()) {
    return (
      <AddressInputGroupContentNew
        state={state}
        shouldAutoFocusPostcodeLookup={shouldAutoFocusPostcodeLookup}
        onAddressSelect={addAddressToState}
        onChange={handleChange}
      />
    );
  }

  return (
    <AddressInputGroupContent
      state={state}
      shouldAutoFocusPostcodeLookup={shouldAutoFocusPostcodeLookup}
      onAddressSelect={addAddressToState}
      onChange={handleChange}
    />
  );
};
