import { isNullOrUndefined } from '../../../utils/useNullOrUndefined';
import { isValidEmail, isValidPhoneNumber } from './validation';

interface ValidationOptions {
  required?: boolean;
  type?: string;
}

export function getFieldValidators({ required, type }: ValidationOptions) {
  const validators = [];

  if (required) {
    validators.push(validateRequired);
  }

  if (type === 'tel') {
    validators.push(validatePhone);
  }

  if (type === 'email') {
    validators.push(validateEmail);
  }

  return validators;
}

function validateRequired(value: unknown) {
  if (
    isNullOrUndefined(value) ||
    (typeof value === 'string' && !value.trim())
  ) {
    return 'This field is required';
  }
}

function validatePhone(value: unknown) {
  if (isNilOrEmptyString(value)) return;

  if (typeof value !== 'string' || !isValidPhoneNumber(value)) {
    return 'Please enter a valid phone number';
  }
}

function validateEmail(value: unknown) {
  if (isNilOrEmptyString(value)) return;

  if (typeof value !== 'string' || !isValidEmail(value)) {
    return 'Please enter a valid email address';
  }
}

function isNilOrEmptyString(value: unknown) {
  return isNullOrUndefined(value) || (typeof value === 'string' && !value);
}
