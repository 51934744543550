// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { env } from './environment';

export const isNewAddressSectionEnabled = () => {
  return true;
};

export const isNewStepProgressBarEnabled = () => {
  return true;
};

export const arePromosAndTermsAndConditionsEnabled = () => {
  return true;
};

export const isNewConfirmationEnabled = () => {
  return true;
};
