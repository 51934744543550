import { FormControl } from '@material-ui/core';
import React from 'react';

import { JobType } from '../utils/jobTypes';
import { JobTypeSelectorButtons } from './JobTypeSelectorButtons';

interface JobTypeInputGroupProps {
  jobTypes: JobType[];
  jobType?: string;
  onJobTypeChange?: (nextJobType: string) => void;
}

export const JobTypeInputGroup = ({
  jobTypes,
  jobType,
  onJobTypeChange,
}: JobTypeInputGroupProps) => {
  const jobTypeSelectedHeading = (
    <div className="marketplace-heading">
      <span className="marketplace-heading__accent">You are booking a</span>
    </div>
  );

  const jobTypeNotSelectedHeading = (
    <div className="marketplace-heading">
      <span className="marketplace-heading__accent">Choose the service</span>{' '}
      you want to book
    </div>
  );

  return (
    <FormControl>
      {jobType ? jobTypeSelectedHeading : jobTypeNotSelectedHeading}

      <JobTypeSelectorButtons
        options={jobTypes}
        value={jobType}
        onChange={onJobTypeChange}
      />
    </FormControl>
  );
};
