export interface JobType {
  value: string;
  label: string;
}

export const MARKETPLACE_JOB_TYPES: JobType[] = [
  {
    value: 'boiler_service',
    label: 'Boiler Service',
  },
  {
    value: 'boiler_service_landlord',
    label: 'Gas Safety Certificate',
  },
  {
    value: 'boiler_service_combo',
    label: 'Gas Safety Cert & Boiler Service',
  },
];

export const PRIVATE_AUTOBOOKING_JOB_TYPES = [
  {
    value: 'boiler_service',
    label: 'Boiler Service',
  },
  {
    value: 'boiler_service_landlord',
    label: 'Gas Safety Certificate',
  },
];
