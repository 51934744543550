import React, { useEffect } from 'react';

export interface AppointmentData {
  jobType: string;
  line1?: string;
  line2?: string;
  town?: string;
  postcode?: string;
  timeSlot?: string;
  fullName?: string;
  email?: string;
  phone?: string;
  acceptPromos?: boolean;
  acceptTerms?: boolean;
}

export const defaultValue = {
  jobType: '',
  line1: '',
  line2: '',
  town: '',
  postcode: '',
  timeSlot: '',
  fullName: '',
  email: '',
  phone: '',
};

export const usePersistedState = (key: string) => {
  const [state, setState] = React.useState<AppointmentData>(
    () => JSON.parse(localStorage.getItem(key) || '""') || defaultValue,
  );
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState] as const;
};
