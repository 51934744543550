import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { Button } from '../../button';
import { AppointmentData } from '../hooks/usePersistedState';
import { AddressData, PostcodeLookup } from './PostcodeLookup';
import { TextField } from './TextField';

interface AddressInputGroupContentNewProps {
  state: AppointmentData;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAddressSelect: (address: AddressData) => void;
  shouldAutoFocusPostcodeLookup?: boolean;
}

export const AddressInputGroupContentNew = ({
  state,
  onChange,
  onAddressSelect,
  shouldAutoFocusPostcodeLookup: shouldAutoFocusPostcodeLookupRaw = true,
}: AddressInputGroupContentNewProps) => {
  const isAddressFilled = Boolean(
    state.line1 || state.line2 || state.postcode || state.town,
  );
  const [isAddressFilledOnce, setAddressFilledOnce] = useState(isAddressFilled);

  useEffect(() => {
    if (isAddressFilled) {
      setAddressFilledOnce(true);
    }
  }, [isAddressFilled]);

  const shouldRenderPostcodeLookupOnly = !isAddressFilledOnce;

  const [isPostcodeLookupFocused, setPostcodeLookupFocused] = useState(false);

  const lookupCardVariant = getLookupCardVariant({
    isAddressFilledOnce,
    isPostcodeLookupFocused,
  });

  const shouldAutoFocusPostcodeLookup =
    shouldAutoFocusPostcodeLookupRaw && !isAddressFilledOnce;

  return (
    <div>
      <div
        className={clsx(
          'marketplace-lookup-card',
          `marketplace-lookup-card--variant_${lookupCardVariant}`,
        )}
      >
        <legend
          className={clsx(
            'marketplace-heading',
            lookupCardVariant === 'highlighted' &&
              'marketplace-heading--color_white',
          )}
        >
          Start typing{' '}
          <span className="marketplace-heading__accent">your address</span>
        </legend>

        <PostcodeLookup
          addressSelectedCallback={onAddressSelect}
          shouldAutoFocus={shouldAutoFocusPostcodeLookup}
          shouldUseNewDesign
          onFocusChange={setPostcodeLookupFocused}
          classes={{ field: 'marketplace-lookup-card__field' }}
        />
      </div>

      {!shouldRenderPostcodeLookupOnly && (
        <>
          <TextField
            label="Address line 1"
            name="line1"
            required
            onChange={onChange}
          />
          <TextField label="Address line 2" name="line2" onChange={onChange} />
          <TextField label="Town" name="town" required onChange={onChange} />
          <TextField
            label="Postcode"
            name="postcode"
            required
            onChange={onChange}
          />

          <div className="marketplace-submit">
            <Button
              className="marketplace-submit__button"
              variant="primary"
              type="submit"
            >
              Confirm address &amp; go to step 2
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

interface LookupCardVariantOptions {
  isAddressFilledOnce: boolean;
  isPostcodeLookupFocused: boolean;
}

function getLookupCardVariant({
  isAddressFilledOnce,
  isPostcodeLookupFocused,
}: LookupCardVariantOptions): 'highlighted' | 'focused' | 'passed' {
  switch (true) {
    case isPostcodeLookupFocused:
      return 'focused';
    case isAddressFilledOnce:
      return 'passed';
    default:
      return 'highlighted';
  }
}
