import React from 'react';

import { AppointmentData } from '../hooks/usePersistedState';

export const getAddress = (data: AppointmentData) =>
  [data?.line1, data?.line2, data?.town, data?.postcode]
    .filter(Boolean)
    .join(', ');

export const AppointmentPreview = ({ data }: { data: AppointmentData }) => {
  return (
    <div className="marketplace__helper__address">
      <p>{getAddress(data)}</p>
    </div>
  );
};
