import {
  CircularProgress,
  ClickAwayListener,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Popper,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import LocationIcon from '@material-ui/icons/LocationOnOutlined';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import React, { useEffect } from 'react';

import { useUpdatingRef } from '../../../utils/useUpdatingRef';
import {
  AddCategory,
  AddressCategory,
  AddressSuggestion,
  useSearchAddress,
} from '../hooks/useSearchAddress';

const ICON_STYLE = { color: '#696C75' };

export const validPostcodeRegex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

export const roundAddressCount = (count: number) => {
  // If it's less than 10, just display it
  if (count < 10) return count;

  // Round number down to nearest 10, add '+'
  count = Math.floor(count / 10) * 10;
  return `${count}+`;
};

export const formatAddressFromPostcoder = (
  address: AddressSuggestion,
): AddressData => {
  // The postcode placement can be random, so use a regex match to pull it out
  const postcode = (`${address.summaryline} ${address.locationsummary}`.match(
    validPostcodeRegex,
  ) as RegExpMatchArray)[0];

  // Remove the postcode if it was present in either strings
  const summaryLine = address.summaryline.replace(`, ${postcode}`, '');
  const locationSummary = address.locationsummary.replace(`, ${postcode}`, '');

  // Split the long string into usable, trimmed sections
  const sections = locationSummary.split(',').map((s) => s.trim());

  const line1 = summaryLine;
  const line2 = sections.length > 2 ? sections[0] : '';
  // The final index will always be the state/province
  // The index before that will always be the town/city
  const town = sections[sections.length - 2];

  return {
    line1,
    line2,
    town,
    postcode,
  };
};

export interface AddressData {
  id?: string;
  line1?: string;
  line2?: string;
  town?: string;
  postcode?: string;
}

type RenderAddressProps = {
  item: AddressSuggestion;
  addCategory: AddCategory;
  selectAddress: (address: AddressData) => void;
};

type CategoryProps = {
  name: string;
  setCategory: (addressCategory: AddressCategory | undefined) => void;
};

const Category = ({ name, setCategory }: CategoryProps) => {
  return (
    <MenuItem
      onClick={() => setCategory(undefined)}
      style={{ backgroundColor: 'lightgrey' }}
    >
      <CancelIcon style={ICON_STYLE} />
      <ListItemText style={{ paddingLeft: 5, whiteSpace: 'normal' }}>
        {name}
      </ListItemText>
    </MenuItem>
  );
};

const BasicAddress = ({ item, selectAddress }: RenderAddressProps) => {
  return (
    <MenuItem onClick={() => selectAddress(formatAddressFromPostcoder(item))}>
      <LocationIcon style={ICON_STYLE} />
      <ListItemText
        style={{ paddingLeft: 5, whiteSpace: 'normal' }}
        primary={`${item.summaryline}, ${item.locationsummary}`}
      />
    </MenuItem>
  );
};

const NestedAddress = ({ item, addCategory }: RenderAddressProps) => {
  const summary = `${item.summaryline}, ${item.locationsummary}`;
  const count = roundAddressCount(item.count);
  return (
    <MenuItem onClick={() => addCategory({ id: item.id, name: summary })}>
      <SearchIcon style={ICON_STYLE} />
      <ListItemText
        style={{ paddingLeft: 5, whiteSpace: 'normal' }}
        primary={summary}
        secondary={`${count} Addresses`}
      />
    </MenuItem>
  );
};

const renderAddress = (props: RenderAddressProps) => {
  if (props.item.type === 'ADD') return <BasicAddress {...props} />;
  else return <NestedAddress {...props} />;
};

const NoAddresses = () => (
  <ListItem>
    <ListItemText primary="No addresses found" />
  </ListItem>
);

export const PostcodeLookup = ({
  shouldAutoFocus,
  addressSelectedCallback,
  shouldUseNewDesign,
  onFocusChange,
  classes,
}: {
  shouldAutoFocus?: boolean;
  addressSelectedCallback: (address: AddressData) => void;
  shouldUseNewDesign?: boolean;
  onFocusChange?: (focused: boolean) => void;
  classes?: { field?: string };
}) => {
  const [
    searchString,
    suggestedAddresses,
    search,
    addCategory,
    currentCategory,
    isAutocompleteLoading,
  ] = useSearchAddress();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);

  const onFocusChangeRef = useUpdatingRef(onFocusChange);
  useEffect(() => {
    onFocusChangeRef.current?.(open);
  }, [open, onFocusChangeRef]);

  const selectAddress = (address: AddressData | null) => {
    if (address) {
      addressSelectedCallback(address);
      window.dataLayer?.push({ event: 'address-selected' });
      setOpen(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length === 1) {
      window.dataLayer?.push({ event: 'start-searching-address' });
    }
    search(event.currentTarget.value);
    setAnchorEl(event.currentTarget);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div
        className={clsx(
          'marketplace-lookup',
          shouldUseNewDesign
            ? 'marketplace-lookup--design_v2'
            : 'marketplace-lookup--design_v1',
        )}
      >
        <div style={{ display: 'flex', position: 'relative' }}>
          <TextField
            fullWidth
            id="searchAddress"
            variant="outlined"
            placeholder="e.g. Manor Farm Barns, Fox Road"
            onFocus={() => setOpen(true)}
            autoFocus={shouldAutoFocus}
            value={searchString}
            onChange={handleChange}
            className={clsx('marketplace-lookup__field', classes?.field)}
          />
          {isAutocompleteLoading && (
            <CircularProgress
              className="marketplace-lookup__spinner"
              size={shouldUseNewDesign ? 20 : 40}
            />
          )}
        </div>
        <Popper
          open={open}
          anchorEl={anchorEl}
          style={{
            backgroundColor: 'white',
            boxShadow: '0.05em 0.2em 0.6em rgba(0, 0, 0, 0.2)',
            width: anchorEl?.offsetWidth,
            maxHeight: '300px',
            maxWidth: '100%',
            overflowY: 'auto',
            zIndex: 1000,
          }}
          placement="bottom-start"
        >
          {currentCategory && (
            <Category name={currentCategory.name} setCategory={addCategory} />
          )}
          <MenuList autoFocusItem={open} id="menu-list-grow">
            {searchString.length > 3 && suggestedAddresses.length === 0 && (
              <NoAddresses />
            )}
            {suggestedAddresses.map((address) =>
              renderAddress({ item: address, addCategory, selectAddress }),
            )}
          </MenuList>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
