import { format } from 'date-fns';
import React from 'react';

import { getAddress } from '../../marketplace/components/AppointmentPreview';
import { AppointmentData } from '../../marketplace/hooks/usePersistedState';

const getSlotName = (timeSlotTime: string) => {
  switch (timeSlotTime) {
    case '08-12':
      return 'Morning';
    case '12-16':
      return 'Afternoon';
    case '16-20':
      return 'Evening';
  }
};

const getSlotTime = (timeSlotTime: string) => {
  switch (timeSlotTime) {
    case '08-12':
      return '8am-12pm';
    case '12-16':
      return '12pm-4pm';
    case '16-20':
      return '4pm-8pm';
  }
};

export const renderTimeSlot = (timeSlot: string) => {
  const [timeSlotDate, timeSlotTime] = (timeSlot ?? '').split('||');
  const date = format(new Date(timeSlotDate), 'EEE d MMMM');
  const slotName = getSlotName(timeSlotTime);
  const time = getSlotTime(timeSlotTime);

  return `${date}, ${slotName} (${time})`;
};

interface AppointmentSummaryLine {
  label: string;
  value: string;
}

interface AppointmentSummaryProps {
  appointmentData: AppointmentData;
}

export const AppointmentSummary = ({
  appointmentData,
}: AppointmentSummaryProps) => {
  const lines: AppointmentSummaryLine[] = [
    {
      label: 'The above availability is based on your address:',
      value: getAddress(appointmentData),
    },
  ];

  if (appointmentData.timeSlot) {
    lines.push({
      label: 'Your prefered appointment is:',
      value: renderTimeSlot(appointmentData.timeSlot),
    });
  }

  return (
    <div className="marketplace-appointment-summary">
      {lines.map((line, i) => (
        <div key={i} className="marketplace-appointment-summary__line">
          <div className="marketplace-appointment-summary__label">
            {line.label}
          </div>
          <div className="marketplace-appointment-summary__value">
            {line.value}
          </div>
        </div>
      ))}
    </div>
  );
};
